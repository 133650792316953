.header {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.header.minimized {
	position: fixed;
	top: 0;
	left: 0;
	width: 200px;
	height: 50px;
	padding: 20px;
	justify-content: flex-start;
}

.header-title {
	font-size: 3em;
	margin: 0;
}

.header-title a {
	cursor: default;
	color: black;
	text-decoration: none;
}

.header.minimized .header-title a {
	cursor: pointer;
	font-size: 20px;
}

.header.minimized .header-title a:hover {
	color: grey;
}

.header.minimized .header-title a:hover::after {
	content: "go back";
	display: flex;
	/* justify-content: center; */
	font-size: 14px;
	color: grey;
}
