* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: "Apercu", sans-serif;
	color: #000;
	padding: 20px;
	background-color: #fff;
}

main {
	max-width: 700px;
	margin: 0 auto;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Regular.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Bold.otf") format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Medium.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Italic.otf") format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Bold Italic.otf") format("opentype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Light Italic.otf") format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Apercu";
	src: url("./fonts/Apercu Pro Medium Italic.otf") format("opentype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Apercu Mono";
	src: url("./fonts/Apercu Pro Mono.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}
