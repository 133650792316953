@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

h1,
h2 {
	font-family: "EB Garamond", serif;
}

header {
	padding: 40px 0;
	text-align: center;
}

header h1 {
	font-size: 2em;
	font-weight: 400;
}

main.blog-post {
	max-width: 700px;
	margin: 0 auto;
	padding: 60px 0;
}

.blog-post h1 {
	font-size: 3em;
	font-weight: 400;
	text-align: center;
	margin-bottom: 10px;
}

.blog-post h2 {
	font-weight: 500;
	margin: 0;
	margin-top: 30px;
}

.blog-post .date,
.blog-post .author {
	text-align: center;
	font-size: 0.9em;
	color: #666;
	margin-top: 10px;
	margin-bottom: 10px;
}

.blog-post .content {
	font-size: 1em;
	color: rgb(28, 28, 28);
	line-height: 1.6em;
	text-align: justify;
	white-space: normal !important;
	white-space: pre-wrap;
}

.blog-post p,
.blog-post ol,
.blog-post ul {
	margin-top: 20px;
	margin-bottom: 20px;
}

.blog-post .content a {
	color: inherit;
	text-decoration: none;
	border-bottom: 1px solid #000;
}

.blog-post .content a:hover {
	border-bottom: 1px solid transparent;
}

.blog-post pre {
	font-size: 14px !important;
	border-radius: 0.5rem !important;
	padding: 0 1em !important;
	line-height: 1.6 !important;
}

.blog-post img {
	max-width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.blog-post ol,
.blog-post ul {
	padding-inline-start: 0;
	margin-left: 3em;
}

.blog-post li {
	text-wrap: pretty;
	margin-bottom: 10px;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	cursor: pointer;
}

.modal-content {
	position: relative;
	max-width: 90%;
	max-height: 90%;
	overflow: hidden;
	border-radius: 8px;
}

.modal-content img {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 8px;
}

.clickable-img {
	cursor: pointer;
	display: block;
	margin: 10px auto;
	max-width: 100%;
	height: auto;
	border-radius: 4px;
	transition: transform 0.2s ease;
}

article {
	margin-bottom: 40px;
}

article h2 {
	font-size: 1.5em;
	font-weight: 500;
	margin-bottom: 10px;
}

article p {
	font-size: 1em;
	color: #333;
	margin-bottom: 10px;
}

article a {
	font-size: 1em;
	color: inherit;
	text-decoration: none;
	border-bottom: 1px solid #000;
}

article a:hover {
	border-bottom: 1px solid transparent;
}

footer {
	text-align: center;
	padding: 20px;
	font-size: 0.9em;
	color: #555;
}
