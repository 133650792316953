.blog-post-preview-link {
	text-decoration: none;
	color: black;
	--indent: 16px;
}

.blog-post-preview {
	padding: 14px 16px 14px var(--indent);
	border-top: 1px solid #ccc;
}

.blog-post-preview:hover {
	padding-left: calc(var(--indent) - 2px);
	border-left: 2px solid #ccc;
}
